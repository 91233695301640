.our-services {
    padding: 6rem 0 3.125rem;

    @media only screen and (max-width: 600px) {
        padding: 6rem 0 0;
    }

    .service-groups {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 35px;
        // margin-bottom: 3.125rem;
        padding: 1rem;

        @media only screen and (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media screen and (max-width: 1000px) and (min-width: 601px) {
            grid-template-columns: repeat(2, 1fr);
        }

        .service-item {
            background: #FFFFFF;
            box-shadow: 0px 4px 30px rgba(185, 189, 222, 0.23);
            border-radius: 1.875rem;
            padding: 2.25rem;
            text-align: center;
            cursor: pointer;

            &.selected {
                background-color: #FFE5FE;

                p {
                    color: #B311FF;
                }
            }

            p {
                font-family: 'Poppins';                
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.875rem;                
                color: #001B13;
            }
        }        
    }

    .service-detail {        
        transition: all 0.5s ease-in-out 0s;
        overflow: hidden;            
        height: 0;
        margin-top: 1rem;
        padding: 1rem;
        
        &-title {
            color: #B311FF !important;
        }

        &.expanded {
            height: 400px;

            @media only screen and (min-width: 1441px) {
                height: 490px;
            }

            @media only screen and (max-width: 900px) and (min-width: 601px) {
                height: 450px;
            }

            @media only screen and (max-width: 600px) {
                height: 800px;
            }
            
            .service-detail-container {
                box-shadow: 0px 4px 30px rgba(185, 189, 222, 0.23);
            }
        }

        .service-detail-container {
            background: #FFFFFF;            
            padding: 1rem;
            border-radius: 1.875rem;
        }

        h3 {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2.25rem;
            color: #99B8AF;

            @media only screen and (max-width: 1440px) {
                font-size: 1rem;
                line-height: 2rem;
            }
        }
    }

    .first-section {
        background-image: url("../images/service-bg1.svg");
        background-position: top right;
        background-repeat: no-repeat;        
        padding: 3.125rem 0.9375rem;

        @media only screen and (max-width: 1200px) {
            background-image: none;
        }
    }

    .second-section {
        background-image: url("../images/service-bg2.svg");
        background-position: top left;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 3.125rem 0.9375rem;

        @media only screen and (max-width: 1200px) {
            background-image: none;
        }
    }

    .section-container {
        .read-more {
            transition: all 0.5s ease-in-out 0s;
            overflow: hidden;            
            height: 0;

            &.expanded {
                height: 320px;

                @media only screen and (min-width: 1441px) {
                    height: 390px;
                }

                @media only screen and (max-width: 1440px) and (min-width: 1201px) {
                    height: 390px;
                }

                @media only screen and (max-width: 800px) {
                    height: 400px;
                }
            }
        }

        .action-groups {
            margin-top: 3rem;
        }
    }
}
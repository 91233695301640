footer {
    background: #020706;

    .footer-bottom-section {
        border-top: 1px solid rgba($color: #fff, $alpha: 0.5);

        &-container {
            max-width: 69.25rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2.5rem 1rem;

            @media only screen and (max-width: 1200px) {
                flex-direction: column-reverse;
            }
    
            p {            
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.6875rem;
                color: #fff;
                margin: 0;

                @media only screen and (max-width: 1200px) {
                    margin-top: 1.5rem;
                }
            }
    
            .social-links {
                display: flex;

                li:first-child {
                    margin-left: 0;
                }
    
                li {
                    list-style: none;
                    width: 4rem;
                    height: 4rem;
                    margin-left: 1.125rem;            
    
                    a {
                        width: 100%;
                        height: 100%;
                        background-color: #fff;
                        border-radius: 3.125rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }    
}
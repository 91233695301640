header {
    &.sticky {
        position: fixed;
        top: 0;
        background-color: white;
        box-shadow: 0 2px 31px 3px rgb(0 0 0 / 17%);
        width: 100%;
        z-index: 100;

        .header-container {
            padding: 1rem;
        }
    }
    .header-container {
        max-width: 82.375rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.25rem 0.9375rem;

        .header-main-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                margin: 0 1.625rem;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.6875rem;
                color: #99B8AF;
                text-decoration: none;

                &.active {
                    color: #B311FF;
                }
            }
        }

        .menu-icon {
            width: 2rem;
            cursor: pointer;
        }
    }

    .mobile-menu {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 12;

        &.move-to-right {
            animation: moveToRight .6s ease both;
        }

        @keyframes moveToRight { 
            from {
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
            to {
                -webkit-transform: translateX(0%);
                transform: translateX(0%);
            }
        }

        &.move-to-left {
            animation: moveToLeft .6s ease both;
        }

        @keyframes moveToLeft { 
            from {
                -webkit-transform: translateX(0%);
                transform: translateX(0%);
            }
            to {
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
        }

        .close-icon {
            position: absolute;
            width: 1.25rem;
            top: 1.5625rem;
            right: 1.5625rem;
            cursor: pointer;
        }

        &-content {            
            padding: 3rem 1rem;

            ul {
                list-style: none;
                padding: 0;

                .btn {
                    display: block;
                    width: 120px;
                }

                li {
                    a:not(.btn) {                        
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.125rem;
                        line-height: 1.6875rem;
                        color: #99B8AF;
                        text-decoration: none;
                        margin-bottom: 1rem;
                        display: block;

                        &.active {
                            color: #B311FF;
                        }
                    }
                }
            }
        }
    }
}
.hero-section {
    background-image: url("../images/hero-bg.svg");
    background-position: top right;
    background-repeat: no-repeat;

    &.sticky {
        padding-top: 9.75rem;
    }

    @media only screen and (max-width: 1200px) {
        background-image: none;
    }

    .section-container {
        padding: 0.5rem 0.9375rem 5rem;

        @media only screen and (max-width: 1200px) {
            padding: 2rem 1rem;
        }

        .text-section {
            position: relative;
        }

        .action-groups {
            margin-top: 3rem;
        }

        .read-more {
            transition: all 0.5s ease-in-out 0s;
            overflow: hidden;            
            height: 0;

            &.expanded {
                height: 150px;

                @media only screen and (min-width: 1441px) {
                    height: 150px;
                }

                @media only screen and (max-width: 1440px) and (min-width: 1201px) {
                    height: 150px;
                }

                @media only screen and (max-width: 800px) {
                    height: 280px;
                }
            }
        }
    }
}
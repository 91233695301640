.our-company {
    padding: 6rem 1rem 3.125rem;
    background-image: url("../images/company-bg.svg");
    background-position: top left;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1200px) {
        background-image: none;
    }

    .section-container {
        .read-more {
            transition: all 0.5s ease-in-out 0s;
            overflow: hidden;            
            height: 0;

            &.expanded {
                height: 320px;

                @media only screen and (min-width: 1441px) {
                    height: 400px;
                }

                @media only screen and (max-width: 1440px) and (min-width: 1201px) {
                    height: 400px;
                }

                @media only screen and (max-width: 800px) {
                    height: 415px;
                }
            }
        }

        .action-groups {
            margin-top: 3rem;

            @media only screen and (max-width: 800px) {
                text-align: center;
            }
        }
    }
}
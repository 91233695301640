.aboutus {
    padding: 2rem 1rem 3.125rem;
    background-image: url("../images/service-bg1.svg");
    background-position: top right;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1200px) {
        background-image: none;
    }

    .aboutText {
        width: 65%;
    }

    .section-container {
        .read-more {
            transition: all 0.5s ease-in-out 0s;
            overflow: hidden;            
            height: 0;

            &.expanded {
                height: 200px;

                @media only screen and (min-width: 1441px) {
                    height: 200px;
                }

                @media only screen and (max-width: 1440px) and (min-width: 1201px) {
                    height: 200px;
                }

                @media only screen and (max-width: 800px) {
                    height: 365px;
                }
            }
        }

        .action-groups {
            margin-top: 3rem;

            @media only screen and (max-width: 800px) {
                text-align: center;
            }
        }
    }
}
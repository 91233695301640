.technologies-section {
    background: #020706;
    padding-top: 4rem;
    .footer-top-section {
        display: flex;
        padding: 2.625rem 1rem;
    
        @media only screen and (max-width: 1200px) and (min-width: 601px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    
        @media only screen and (max-width: 600px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    
        .link-group-item:first-child {
            width: 34%;
    
            @media only screen and (max-width: 1200px) {
                width: 100%;
            }
        }
    
        .link-group-item:not(:first-child) {
            width: 34%;
    
            @media only screen and (max-width: 1200px) {
                width: 100%;
            }
        }
    
        .link-group-item {
            @media only screen and (max-width: 1200px) and (min-width: 601px) {
                margin: 1rem 0;
            }
    
            @media only screen and (max-width: 600px) {
                margin-top: 1.5rem;
            }
    
            h5 {
                font-family: 'Poppins';                
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.875rem;
                color: #FFFFFF;
                margin: 0 0 2.625rem;
            }
    
            ul {
                padding: 0;
    
                li {
                    list-style: none;
                    margin-bottom: 1.875rem;
                    font-family: 'Poppins';                    
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: rgba(255, 255, 255, 0.6);
    
                    a {                        
                        text-decoration: none;                        
                        font-family: 'Poppins';                    
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        color: rgba(255, 255, 255, 0.6);
    
                        &:hover {
                            color: #fff;
                        }
                    }
    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}